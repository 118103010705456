import { getAPIPrefix } from '../config/APIEndpoints';
import { _postRequest, _memberOnlyRequest } from './_apiReq';

export const model = {
  dbList(page, perPage, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append("per_page", perPage);
    formData.append("page_number", page);

    _postRequest(
      getAPIPrefix() + 'model-db-list',
      formData,
      result => cb(result),
      error => errorCb({ message: error })
    );
  },
  getPresignUrl(fileNames, hasZip = false, thumb = false, unifyMesh = false, cb, errorCb) {
    const formData = new FormData();

    formData.append("token", localStorage.getItem("token"));
    formData.append("files", fileNames.join("||"));
    
    if (thumb)
      formData.append("platform", "Windows");
    else
      formData.append("process_mat", hasZip ? "yes" : "no");

    if (unifyMesh)
      formData.append("unify_mesh", "yes");

    _memberOnlyRequest(
      getAPIPrefix() + (thumb ? "upload-model-thumb" : "model-upload"),
      formData,
      result => cb(result),
      error => errorCb({ message: error.message, showErrorModal: error.showErrorModal }),
      "Could not upload the file. Please try again!"
    );

  },
  dbSave(fileName, cb, errorCb) {
    const url = getAPIPrefix() + 'model-save';
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("fileName", fileName);

    _postRequest(
      url,
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      "Could not save the file in db.Please try again!"
    );
  },

  getList(page, perPage, nextPageToken = null, cb, errorCb) {
    const formData = new FormData();
    const url = getAPIPrefix() + 'model-list';
    formData.append("token", localStorage.getItem("token"));
    formData.append("MaxKeys", page === 0 ? perPage : perPage);
    if (nextPageToken) {
      formData.append("NextContinuationToken", nextPageToken);
    }

    _postRequest(
      url,
      formData,
      result => {
        let xmlData = "";
        var request = new XMLHttpRequest();
        request.open("GET", result.url, true);
        request.overrideMimeType("text/xml");
        request.onload = function () {
          if (request.readyState === request.DONE) {
            if (request.status === 200) {
              xmlData = request.responseText;
              cb({ xmlData: xmlData, ...result });
            }
          }
        };
        request.send(null);
      },
      errorMessage => {
        errorCb({ message: errorMessage });
      }
    );
  },
  download(fileName, cb, errorCb) {
    const url = getAPIPrefix() + 'model-download';
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("file_name", fileName);

    _postRequest(
      url,
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      "Could not download the file.Please try again!"
    );
  },
  delete(fileName, cb, errorCb) {
    const url = getAPIPrefix() + 'model-delete';
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("file_name", fileName);

    _postRequest(
      url,
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      "Could not delete the file.Please try again!"
    );
  },
};
